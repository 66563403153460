import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import Image from '../Image';
import { ucFirst } from '../../utils/caseconverters';
import LazyLoad from 'react-lazyload';

import Icon from '../Icon';
import IconAnimated from '../IconAnimated/IconAnimated';
import classNames from 'classnames';
import styles from './ProductList.module.scss';

const ProductList = ({ theme = '', title = '', image = {}, items = [] }) => {
    const classes = classNames(
        styles['ProductList'],
        styles['ProductList--' + ucFirst(theme)]
    );

    return (
        <section className={classes}>
            <div className={styles['ProductList__Container']}>
                <div className={styles['ProductList__ImageContainer']}>
                    {!!image.url && (
                        <LazyLoad>
                            <div className={styles.ProductList__Image}>
                                <Image
                                    {...{ image }}
                                    sizes={
                                        '(min-width: 1680px) 1200px, (min-width: 1200px) 828px'
                                    }
                                />
                            </div>
                        </LazyLoad>
                    )}
                </div>
                <div className={styles['ProductList__Content']}>
                    <h2
                        className={styles['ProductList__Title']}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <ul className={styles['ProductList__List']}>
                        {items.map((item, index) => (
                            <CardItem
                                key={index}
                                index={index}
                                theme={theme}
                                {...item}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

ProductList.propTypes = {
    theme: PropTypes.string,
    image: PropTypes.shape({
        renditions: PropTypes.shape({
            mobile: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            mobile2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
            desktop2x: PropTypes.shape({
                src: PropTypes.string,
                width: PropTypes.number,
                height: PropTypes.number,
            }),
        }),
        focal: PropTypes.shape({
            x: PropTypes.string,
            y: PropTypes.string,
        }),
        altText: PropTypes.string,
    }),
    title: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            icon: PropTypes.string,
            title: PropTypes.string,
            text: PropTypes.string,
        })
    ),
};

const CardItem = ({
    icon = '',
    text = '',
    theme = '',
    index = null,
    link = {},
}) => {
    const isBlueTheme = theme === 'blue';
    const [shouldStart, setStart] = useState(false);

    return (
        <li className={styles['CardItem']}>
            <a
                className={styles['CardItem__Link']}
                href={link.href}
                target={link.target}
                onMouseEnter={() => setStart(true)}
                onMouseLeave={() => setStart(false)}>
                <span className="sr-only">{link.title}</span>
            </a>
            {!isBlueTheme && icon && (
                <div className={styles['CardItem__Icon']}>
                    {!!icon && (
                        <IconAnimated
                            {...{ icon }}
                            id={`ProductList-${index}-`}
                            shouldStart={shouldStart}
                        />
                    )}
                </div>
            )}
            <div className={styles['CardItem__Content']}>
                {!!link.title && (
                    <h3 className={styles['CardItem__Title']} aria-hidden>
                        {link.title}
                        <span className={styles['CardItem__LinkIcon']}>
                            {!isBlueTheme && (
                                <Icon type={'arrow'} size={'medium'} />
                            )}
                        </span>
                    </h3>
                )}
                <p className={styles['CardItem__Text']}>{text}</p>
            </div>
            {isBlueTheme && (
                <div className={styles['CardItem__RightIcon']}>
                    <Icon type={'arrow'} size={'medium'} />
                </div>
            )}
        </li>
    );
};

CardItem.propTypes = {
    icon: PropTypes.string,
    link: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    text: PropTypes.string,
};

export default ProductList;
